import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ReactPlayer from "react-player"
import './LivePage.css'
import Page from '../page/Page';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

export default function LivePage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <div>
         <hr />
        </div>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
      >
        <Tab label="Morning Service - 11am" />
        <Tab label="Evening Service - 6pm" />
        {/* <Tab label="Youtube channel" /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className='player-wrapper'>
          <ReactPlayer
           className='react-player'
           config={{
              youtube: {
                playerVars: { showinfo: 1 }
              }
            }}
            width='100%'
            height='100%'
            controls="false"
            url="https://www.youtube.com/playlist?list=PLn7Cdk9uDbNv04elNDTOvh3Sl6oDCln03" />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            width='100%'
            height='100%'
            controls="false"
            url="https://www.youtube.com/playlist?list=PLn7Cdk9uDbNtIpLMP4YHOJHRiwNrjaazZ" />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            width='100%'
            height='100%'
            controls="false"
            url="https://www.youtube.com/watch?v=_wxccuG2smI&list=UUZ-I6sZo2plhiUM1kb7ts-Q&ab_channel=BarvasFreeChurch" />
        </div>
      </TabPanel> */}
      {/* <div>
        <h3><strong>Not working?</strong></h3>
        <p> Services should start streaming by 11am and 6pm every Sunday. However, there may be a short delay.</p>
        <p>
          There may be a short delay while we get the stream up and running. If the link is still not working by 11.10am or 6.10pm, head to our
        <a href="https://www.youtube.com/channel/UCZ-I6sZo2plhiUM1kb7ts-Q"> Youtube channel </a>
         and see if you can find the live service there.
    </p>
      </div> */}
    </Page>
  );
}