import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../page/Page';

const NotFound = () => (
  <Page>
    <h1>404 - Page Not Found!</h1>
    <Link to="/">
      Go Home
    </Link>
  </Page>
);

export default NotFound;