import React from 'react';
import Page from '../page/Page';
import PageBanner from '../page/PageBanner';
import './GivingPage.css'
import BibleVerse from '../utils/BibleVerse'
import { Container, Row, Col } from "react-bootstrap";
import BFCComponent from '../bfc/BFCComponent'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const style = {
  width: "100%",
  maxWidth: "500px",
  minWidth: "250px",
  maxHeight: "none!important",
}

export default function GivingPage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <PageBanner imageUrl="/img/giving.jpeg" titleColour="white" title="DONATE" />
      <div class="card card0 mb-3 container shadow">
        <div class="row no-gutters">
          <p>
            <strong>Every man according as he purposeth in his heart, so let him give; not grudgingly, or of necessity: for God loveth a cheerful giver. </strong>
            <span><BibleVerse reference="2CO.9.7" text="2 Corinthians 9:7" /></span>
          </p>

          <p>A number of people have been asking about how they can continue to support the Lord’s work financially while the normal weekly meetings are suspended. </p>
          {/* <p> If you are a tax payer and do not already give by Gift Aid, we would encourage you to do so. You can fill in the form below.</p> */}
          <p> There are two methods to donate shown below - Donorbox and Gift Aid.</p>
          <p>For other ways to donate please contact <a href="mailto:ivantheterrible@tinyonline.co.uk"><i className="fas fa-envelope-square"></i> Ivan Brown (Treasurer)</a></p>
          {/* <p>You can give Gift Aid or via a bank transfer: Sort Code: XX-XX-XX, Account Number: YYYYYYY</p> */}
        </div>
      </div>

      <div class="card card0 mb-3 container shadow">
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            <Tab label="Donorbox" />
            <Tab label="Gift Aid" />
          </Tabs>

          <TabPanel value={value} index={0}>
            <div>
              <h2>Donorbox Form</h2>
              <iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no"
                seamless="seamless" src="https://donorbox.org/embed/barvasfreechurch"
                style={style}></iframe>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div>
              <h2>Gift Aid Form</h2>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScFeLVuM0wD-1_6vrhGbXN0QoyMzmbBUcC1XE5pRCeJODA5gw/viewform?embedded=true"
                width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>

            <p> Alternatively you can download the gift aid form below. Please print, complete, scan and return via email to
              <a href="mailto:ivantheterrible@tinyonline.co.uk"> Ivan Brown (Treasurer)</a></p>

            <ul>
              <li> <a href="https://forms.gle/J7kLHJNrHBmBZczu7" target="_blank" rel="noopener noreferrer">Gift Aid Form (Online)</a></li>
              <li>  <a href="media/gift-aid.doc">Gift Aid Form (Word Document)</a></li>
              <li>  <a href="media/gift-aid.pdf">Gift Aid Form (PDF)</a></li>
            </ul>
          </TabPanel>
      </div>
    </Page>
  )
}
