import React from 'react';
import './AboutPage.css'

class WhatsOnSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div>
       
        <h2>Lord's Day Services</h2>
        <table className="table">
          <tbody>
            <tr><td>Morning service</td><td>11am</td></tr>
            <tr><td>Sunday school</td><td>11am</td></tr>
            <tr><td>Evening service</td><td>6pm</td></tr>
            <tr><td>Youth fellowship</td><td>2nd and 4th Sundays of the month.</td></tr>
          </tbody>
        </table>

        <h5><strong>What happens during the service? </strong></h5>
        <hr></hr>
        <p>
        Our Lord’s Day Morning worship typically follows the pattern below and last for about one hour
        </p>

        <ul>
          <li>Welcome and Intimations</li>
          <li>Opening Praise (we sing Psalms)</li>
          <li>Children's Address</li>
          <li>Bible Passage Reading</li>
          <li>Prayer</li>
          <li>Sermon (normally around 25-30 mins)</li>
          <li>Prayer</li>
          <li>Closing Praise</li>
          <li>Benediction</li>
        </ul>

        <h2>During the Week</h2>
        <table className="table">
          <tbody>
            <tr><td>Bible study</td><td>Every Wednesday night at 7.30pm
        </td></tr>
            <tr><td>Little Rainbows Toddler Group</td>

              <td><ul>
                <li>Every Thursday (10am - 12noon) @ Barvas Free Church Hall</li>
                <li>Tea, coffee, Juice and biscuits every Thursday morning.
              <li>  A time for catching up, and allowing toddlers to play
                in a fun, safe, environment. </li>
                  <li>Cost: &#163; 1</li>
                </li>
              </ul></td></tr>
            <tr><td>Christianity Explored</td><td>TBC</td></tr>
          </tbody>
        </table>
        <br />
      </div>
    )
  }
}
export default WhatsOnSection;
