import React from 'react';

const UsefulLinksSection = (props) => {

    return (<div className="members-page-section">
        <h2>Recommended Websites</h2>
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Christian Life &#38; Learning</th>
                    <th scope="col">Church &#38; Mission</th>
                    <th scope="col">Kids &#38; Young People</th>
                </tr>
            </thead>
            <tbody>

                <tr>
                    <td><a href="https://www.desiringgod.org/">Desiring God</a></td>
                    <td><a href="https://freechurch.org/">Free Church of Scotland</a></td>
                    <td><a href="https://www.freechurchyouthcamps.org/">Free Church Youth Camps</a></td>
                </tr>

                <tr>
                    <td><a href="https://www.thegospelcoalition.org/">The Gospel Coalition</a></td>
                    <td><a href="https://ets.ac.uk/">Edinburgh Theological Seminary</a></td>
                    <td><a href="https://scriptureunion.org.uk">Scripture Union</a></td>
                </tr>

                <tr>
                    <td><a href="https://www.solas-cpc.org/">Solas</a></td>
                    <td><a href="https://ets.ac.uk/mission">ETS Centre for Mission</a></td>
                </tr>

                <tr>
                    <td><a href="https://www.biblegateway.com/">Bible Gateway</a></td>
                    <td><a href="https://generation-mission.org/">Generation Mission</a></td>
                </tr>

                <tr>
                    <td><a href="https://www.esv.org/resources/mobile-apps/">ESV Bible App</a></td>
                    <td><a href="https://womenformission.org/">Women For Mission</a></td>
                </tr>

                <tr>
                    <td><a href="https://www.biblegateway.com/audio/suchet/nivuk/Gen.1">Audio Bible by David Suchet</a></td>
                    <td><a href=" https://20schemes.com/">20 Schemes - Church Planting</a></td>
                </tr>

                <tr>
                    <td><a href="https://www.ligonier.org/">Ligonier Ministries</a></td>
                </tr>

            </tbody>
        </table>
    </div>
    )
}

export default UsefulLinksSection;
