import React from 'react';
import Page from '../page/Page';
import PageBanner from '../page/PageBanner';
import PageSidebar from '../page/PageSidebar';
import PageSection from '../page/PageSection';
import WelcomeSection from './WelcomeSection';
import HistorySection from './HistorySection';
import MinistersSection from './MinistersSection';
import WhatWeBelieveSection from './WhatWeBelieveSection';
import UsefulLinksSection from './UsefulLinksSection';
import WhatsOnSection from './WhatsOnSection';
import CalendarSection from '../calendar/calendar';
import './AboutPage.css'
import BFCComponent from '../bfc/BFCComponent'

class AboutPage extends BFCComponent {
  handleSectionChange = (bannerImage, title, bannerMobilePosition, titleColour) => {
    this.setState({
      currentBannerImage: bannerImage,
      title:title,
      titleColour: titleColour,
      bannerMobilePosition:bannerMobilePosition
    })
  }

  render() {
    return (
      <Page>
        <PageBanner 
        title={this.state.title}
        imageUrl={this.state.currentBannerImage}
        bannerMobilePosition={this.state.bannerMobilePosition} 
        titleColour="red"/>

        <PageSidebar onSectionChange={this.handleSectionChange} 
        currentSection={this.state.currentSection}>

          <PageSection
            linkTitle="About"
            bannerTitle="About"
            // bannerImageUrl="/img/church.jpg"
            bannerImageUrl="img/pencil2.jpg"
            url="/about"
            bannerMobilePosition="center">
            <WelcomeSection/>
          </PageSection>

          <PageSection
            linkTitle="History"
            bannerTitle="HISTORY"
            bannerImageUrl="/img/church_1934.jpg"
            // bannerImageUrl="/img/history2.png"
            url="/about/history"
            titleColour="black"
            bannerMobilePosition="center">
            <HistorySection/>
          </PageSection>

          <PageSection
            linkTitle="Ministers"
            bannerTitle="MINISTERS"
            url="/about/ministers"
            bannerImageUrl="/img/church.jpg"
            // titleColour="white"
            bannerMobilePosition="center">
            <MinistersSection/>
          </PageSection>

          <PageSection
            linkTitle="What We Believe"
            bannerImageUrl="/img/what_we_believe.png"
            url="/about/what-we-believe"
            // titleColour="white"
            bannerMobilePosition="center">
            <WhatWeBelieveSection />
          </PageSection>

          <PageSection
          linkTitle="Useful Links"
            bannerImageUrl="/img/useful_links.jpg"
            url="/about/useful_links"
            // titleColour="white"
            bannerMobilePosition="center">
            <UsefulLinksSection/>
          </PageSection>

          {/*<PageSection
            linkTitle="What's On"
            bannerImageUrl="/img/slides/testimony.png"
            url="/about/whats-on"
            bannerMobilePosition="center">
            <WhatsOnSection/>
          </PageSection>

           <PageSection
          linkTitle="Church Calendar"
            bannerImageUrl="/img/useful_links.jpg"
            url="/about/calendar"
            bannerMobilePosition="center">
            <CalendarSection/>
          </PageSection> */}
        </PageSidebar>
      </Page>
    )
  }

}

export default AboutPage;
