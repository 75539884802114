import React from 'react';
import Page from '../page/Page';
import Map from '../map/Map';

export default function ContactPage() {
  return (
    <Page>
        <Map
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}></Map>

        <br/>
        <h4>Accessibility</h4>
        <p>
          Our building has wheelchair access at the rear of the building and accessible toilets on the ground floor.
        <br />Should you have any accessibility requirements, please speak to our welcome team who will be happy to assist you. </p>
    </Page>
  )
}