import React from 'react';

class BFCCompnent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        window.scrollTo(0, 0)
    }
}

export default BFCCompnent;