import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './BFC.css';

class BFC extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>       
        <Header />
      
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default BFC;
