import React from 'react';
import Page from '../page/Page';
import Profile from '../login/profile';
import './AdminPage.css'

class AdminPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Page>
        <div>
          <h4><strong> PROFILE </strong> </h4>
          <Profile />
        </div>
      </Page>
    )
  }
}

export default AdminPage;
