import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch, useHistory } from 'react-router-dom';
import BFC from './components/bfc/BFC'
import HomePage from './components/home/HomePage2'
import ModalMission from './components/home/ModalMission'
import AboutPage from './components/about/AboutPage';
import LivePage from './components/live/LivePage';
import SermonsPage from './components/sermons/SermonsPage'
import ContactPage from './components/contact/ContactPage'
import VisitPage from './components/contact/VisitPage'
import AdminPage from './components/admin/AdminPage'
import GivingPage from './components/giving/GivingPage'
import EventsPage from './components/events/EventsPage'
import WordForTheWeekPage from './components/word-for-the-week/WordForTheWeekPage'
import ProtectedRoute from "./components/auth/protected-route";
import GA from './components/utils/GoogleAnalytics'
import NotFound from './components/error/NotFound';

const Index = () => {
  // const { isLoading } = useAuth0();
  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    <BrowserRouter>
    { GA.init() && <GA.RouteTracker /> }
      {/* <Auth0ProviderWithHistory> */}
        <BFC>
          <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/live" component={LivePage} />
          <Route path="/sermons" exact component={SermonsPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/giving" component={GivingPage} />
          <Route path="/events" component={EventsPage} />
          <Route path="/word-for-the-week" component={WordForTheWeekPage} />
          <Route path="/visit" component={VisitPage} />
          <ProtectedRoute path="/admin" component={AdminPage} />
          <Route path='*' exact={true} component={NotFound} />
          {/* <Route component={NotFound}/> */}
          {/* <Route path="/callback" render={(props) => {
            handleAuthentication(props);
            return <Callback {...props} />
          }} /> */}
          </Switch>
        </BFC>
      {/* </Auth0ProviderWithHistory> */}
    </BrowserRouter>
  )
}
ReactDOM.render(<Index />, document.getElementById('root'));