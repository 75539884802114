import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import SocialFollow from "./SocialFollow"

const Footer = (props) => {
  return (
    <div className="footer">
      <SocialFollow />
      <div className="footer-links">
        <Link to="/about">About</Link><span className="footer-link-slash">/</span>
        {/* <Link to="/events">Newsletter</Link><span className="footer-link-slash">/</span> */}
        <Link to="/events">Events</Link><span className="footer-link-slash">/</span>
        <a href="https://sermons.barvas.freechurch.org/sermons">Sermons</a><span className="footer-link-slash">/</span>
        <Link to="/contact">Contact</Link><span className="footer-link-slash">/</span>
        <Link to="/live"></Link>
      </div>

      <div className="row">
        <div className="col-sm">
          © {new Date().getFullYear()}  Barvas Free Church&nbsp; &#124;
            Charity No: SC036760 &nbsp;
        </div>
      </div>
    </div>)
};

export default Footer;
