import React from 'react';
import BibleVerse from '../utils/BibleVerse'

const WhatWeBelieveSection = (props) => {
  return (
    <div>
      <h2>What We Believe</h2>
      <p>
        Barvas Free Church exists to glorify God and enjoy Him forever. This means that our purpose is to love God as our Creator and living for Him as our Saviour.
</p>
      <p>
        God has demonstrated His love towards us by giving us His only begotten Son, Jesus Christ, that whosoever would believe in Him will not perish but receive the gift of eternal life (John 3:16). Jesus Christ is the Son of God, Who lived a sinless life and died as a sinless sacrifice upon the cross, bearing the punishment we deserve for our sin and rose from the dead after three days.
</p>
      <p>
        As sinners we are now invited to receive forgiveness and enter into a personal relationship with God by trusting in His Son Jesus Christ by faith. We believe that this is the teaching of the <strong>Bible</strong>.
</p>
      <p>
        Jesus said, “I am the Way, the Truth and the Life, no one can come to the Father, except through me” (<BibleVerse reference="JHN.14.6" text="John 14:6" />)
</p>

      <h2>We are Biblical </h2>

      <p>As a Biblical congregation, we believe that the Bible is the inspired, inerrant, and infallible Word of God for all mankind. It is our instruction manual for life, teaching us not only how to have a relationship with God, but also how to have relationships with others.
</p>
      <p>
        The Bible is God’s Special Revelation to all mankind, revealing to us who God is, who we are, and why we exist. The Bible explains the truth about every area of life, death, heaven and hell.
</p>
      <p>
        Jesus Christ is the Hero in Bible; He was promised in the Old Testament and presented in the New Testament as the only Saviour of sinners. Therefore, the only way to know God and live our lives the way God intended, is through faith in Jesus Christ. All of our Biblical beliefs can be found in the
  <a href="media/confession_of_faith.pdf"> Westminster Confession of Faith</a>
      </p>

      <p>
        All Scripture is breathed out by God and profitable for teaching, for reproof, for correction, and for training in righteousness, that the man of God may be competent, equipped for every good work. (<BibleVerse reference="2TI.3.16-17" text="2 Timothy 3:16-17" />)
</p>
<br></br>

      <h2>We are Reformed </h2>
      <p>
        As a reformed church, we hold to five basic, but fundamental truths which are taught in the Bible
<ul>
          <li><strong>
            Scripture Alone</strong> – The Bible is our highest authority and sufficient for every area of life.
          </li>
          <li><strong>Faith Alone</strong> – We are saved from sin, not by works but by faith in Jesus Christ’s death and resurrection.
        </li>
          <li><strong>Grace Alone</strong> – Salvation is received not achieved. It’s a gracious gift from God received by faith.
        </li>
          <li><strong>Christ Alone</strong> – Jesus Christ is the only Saviour of Sinners. There is nothing and no one else who can save you from sin, except Jesus.
        </li>
          <li><strong>Glory to God Alone</strong> – We were created to glorify God and enjoy Him forever. We can do this Loving Jesus & Loving People.
        </li>
        </ul>
      </p>

      <p>
        For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast.  (<BibleVerse reference="EPH.2.8-9" text="Ephesians 2:8-9" />)
</p>

<br></br>
      <h2>We are Evangelical</h2>
      <p>As an evangelical church we seek to proclaim the Gospel of our Lord Jesus Christ. This is the Great Commission the Church has received from Jesus Christ:
</p>
      <p><i>
        Jesus said, "All authority in heaven and on earth has been given to me. Go therefore and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit, teaching them to observe all that I have commanded you. And behold, I am with you always, to the end of the age." Matthew 28:18-20
        </i></p>
      <p>As an evangelical church we seek to share the message of Jesus Christ with everyone, regardless of who they are and what they have done. The Good News message that Jesus Christ came to save us from our sins is to be made know to all people.
</p>
      <p>
        We seek to do this both practically and personally, by getting alongside people in our community and inviting them to come to faith in Jesus Christ.
</p>
      <p>The mission is big, but we love to work with and pray for other mission organizations as they too seeking to fulfil the Great Commission.
</p>
<br></br>

      <h2>We are Presbyterian</h2>
      <p>
        The name of our congregation indicates that we belong to the 
<a href="https://freechurch.org/">Free Church of Scotland</a>. We are part of a Presbyterian denomination which has over 100 congregations located throughout Scotland, London & Canada.
</p>
      <p>
        Barvas Free Church is part of a group of eighteen congregations called the <a href="https://www.facebook.com/WesternIslesPresbytery/">Western Isles Presbytery</a>. There are six Presbyteries in the Free Church of Scotland.  As a Presbyterian denomination, we seek to support and encourage one another both practically and prayerfully.
</p>

    </div>
  )
}

export default WhatWeBelieveSection;
