import React from 'react';

const MinstersSection = (props) => {
  return(
    <div>
     <h4><strong>Ministers</strong></h4>
<p>Since 1900, Barvas Free Church has been served by the following ministers –
</p>

<p>
  <strong>
Rev. Roderick MacKenzie</strong>, from Crossbost, was ordained and inducted to Barvas in 1904.  During the 1900 Union controversy, he was the only student in the Free Church College.  Rev. MacKenzie, an articulate and popular preacher, was translated to Back Free Church in 1910.
</p>

<p>
  <strong>
Rev. Murdo Macdonald from Bernera</strong>, Uig, was ordained and inducted to Barvas in 1915.  Rev Macdonald, a quiet man of a retiring disposition, described as “a gentleman of sterling qualities” and greatly loved by the congregation, died in 1918, at his mother’s home in Stornoway, after only 3 years in Barvas.  He is interred in Barvas Cemetery.
</p>

<p>
  <strong>Rev. John Macleod</strong>, from Leurbost, was minister of Lochcarron Free Church when he accepted a call to Barvas.  He was inducted to Barvas in 1922.  Rev. Macleod was author of the book “Am Measg nan Lili” published in 1948.  The book details the impact of the Gospel on the West Side of Lewis in the eighteenth and nineteenth centuries and profiles the spiritual journey of Norman Macdonald affectionately known as “Tormod Sona”.  The book was revised and published as a booklet, entitled “Pronnagan - Gospel Advent in Barvas Parish” in 2008 by Barvas Free Church Deacons’ Court, as part of its efforts to raise funds for the Barvas Free Church Hall Appeal (renovation project).
Rev Macleod retired from Barvas Free Church in 1956.  He died in 1963 and is interred in Crossbost Cemetery.  His grave is marked by a headstone provided by the Barvas Free Church congregation in remembrance and honour of a faithful minister who laboured in the congregation for 34 years.  
</p>

<p>
  <strong>Rev Kenneth J Nicolson</strong>, from Gravir, was inducted to Barvas Free Church, from Portree, in 1957.   Rev Nicolson, a gifted and talented preacher with remarkable vitality and vigour served in Barvas for 22 years.  In 1976 he was elected Moderator of the General Assembly of the Free Church of Scotland.  He retired from Barvas on 2nd August 1979 and made his home in Crossbost.  Rev Nicolson continued to preach throughout the island and elsewhere, as supply minister, until in his 90’s.  In 2004 he celebrated 60 years in the ministry and in 2007 he and his wife Rhoda celebrated their Diamond Wedding Anniversary.  
Rev Nicolson, also a notable poet, was an accomplished composer of numerous spiritual songs and verses.  A book, compiled by his daughter Peggy Macdonald, also a talented composer of poetry and verse, featuring many of his spiritual songs, was recently published.    
Rev Nicolson died in January 2010 at the age of 98 years and is interred in Crossbost Cemetery.
</p>

<p>
  <strong>Rev Murdo MacAulay Macleod</strong>, from Stornoway, was inducted to Barvas Free Church, from Portree, in 1980.  He served in Barvas for 14 years and was a man of deep Biblical and spiritual convictions with an enthusiastic devotion for the Christian cause.  Rev Macleod retired from Barvas in 1994 and moved to the family croft at Cross Street, Stornoway.  He continued to preach by providing supply cover in vacant congregations as well as undertaking Gaelic preaching duties in other churches.  Rev Macleod left the Free Church in the year 2000 to join the Free Church of Scotland (Continuing). 
Rev Macleod passed away in July 2014 having served the ministry for 47 years.  He was predeceased by his wife Jessie Margaret, a former Church missionary worker, who had strong family ties with Uig.  Both are interred in Ardroil Cemetery, Uig.
</p>

<p>
  <strong>Rev Kenneth Macleod</strong> was a native of Barvas and known locally as Coinneach Ruadh Mhurchaidh Goraidh.  He was inducted to Barvas Free Church, from Maclean Presbyterian Church, New South Wales, Australia, in 1996.  He was born in Lemreway, Lochs, where his father was a lay preacher.  
Rev Macleod brought a wealth of experience in Ministry, Church matters and in preaching the Word with him to Barvas.  As well as serving in Australia he was a former minister of Tain Free Church.  
Rev Macleod was elected Moderator of The General Assembly of the Free Church of Scotland in 1999 and steered the church successfully through the tumultuous time of schism, in 2000, when the Free Church of Scotland (Continuing) was formed. 
Rev Macleod retired from Barvas Free Church in 2000 and returned to Maclean, Australia, with his wife Catherine and most of his family.  He continues to take an active role in church matters and a special interest in his beloved Barvas.
</p>
  
<p>
  <strong>Rev Calum I Macleod</strong>, from Scalpay, was ordained and inducted to Barvas in 2003.  He was amongst the first Free Church College students to graduate with the new University of Glasgow degree, Bachelor of Theology.  
Rev Macleod served faithfully in Barvas for 7 years.  In 2010 he accepted a call to Back Free Church.
</p>


<p>
  <strong>Rev Murdo M Campbell</strong>, the present incumbent, from Melbost, Stornoway, was ordained and inducted to Barvas in August 2014, at the age of 26 years.  He is the youngest ever minister to be inducted to Barvas.  Rev Campbell had previously undertaken placements in Barvas and had accepted the call in person.  Rev Campbell’s wife Alison, nee MacVicar, has strong family ties with Shader, Barvas. 
Rev and Mrs Campbell have a family of 3 sons – David, Finlay and Daniel Murdo (born 2015) who has the distinction of being the first child born to a serving Barvas Free Church minister in 90 years.
</p>
    </div>
  )}

export default MinstersSection;
