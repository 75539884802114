import React from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import postscribe from 'postscribe';
import './AboutPage.css'

function postscribefunction() {
  postscribe('#newsletter-archive', 
  '<script language="javascript" src="//freechurch.us7.list-manage.com/generate-js/?u=041f0b1985642e24b219df2ae&fid=27662&show=10" type="text/javascript"></script>')
}

class NewsletterSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    postscribefunction();
  }

  render() {
    return (
      <div>
      <div id="newsletters"></div>
        {/* <h2>Newsletters</h2> */}
        <p>
        <div font-size="20px" id="newsletter-archive"></div>
        </p>

      </div>
    )
  }
}
export default NewsletterSection;
