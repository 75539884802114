import React from 'react';
import Page from '../page/Page';
import PageBanner from '../page/PageBanner';
import './DonorBox.css'
import BibleVerse from '../utils/BibleVerse'
import { Container, Row, Col } from "react-bootstrap";
import BFCComponent from '../bfc/BFCComponent'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const style = {
  width: "100%",
  maxWidth: "500px",
  minWidth: "250px",
  maxHeight: "none!important",
}

export default function GivingPage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <PageBanner imageUrl="/img/giving.jpeg" titleColour="white" title="DONATE" />
      <div class="card card0 mb-3 container shadow">
        <div class="row no-gutters">
          <p>
            <strong>Every man according as he purposeth in his heart, so let him give; not grudgingly, or of necessity: for God loveth a cheerful giver. </strong>
            <span><BibleVerse reference="2CO.9.7" text="2 Corinthians 9:7" /></span>
          </p>

          </div>
          </div>

      <center><div class="card card0 mb-3 container shadow center">
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            <Tab label="Donorbox" />
          </Tabs>

          <TabPanel value={value} index={0}>
            <div>
              <h2>Donorbox Form</h2>
              <iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no"
                seamless="seamless" src="https://donorbox.org/embed/barvasfreechurch"
                style={style}></iframe>
            </div>
          </TabPanel>

      </div></center>
    </Page>
  )
}
