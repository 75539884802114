// import './CalendarPage.css'
import Page from '../page/Page';
import BFCCompnent from '../bfc/BFCComponent';
import React from 'react';

const style = {
    minHeight: "700px",
    minWidth: "100%",
    maxWidth: "100%",
    border: "1px solid #ddd",
    boxSizing: "border-box"
}

class CalendarPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }

    render() {
        return (
            <div>
                <h2>Events</h2>
                <iframe
                    src="https://calendar.google.com/calendar/embed?src=barvasfreechurch%40gmail.com&ctz=Europe%2FLondon&amp;mode=AGENDA"
                    style={style}
                    width="800"
                    height="600"
                    frameBorder="0"
                    scrolling="no">
                </iframe>
            </div>
        )
    }
}

export default CalendarPage;


