import React from 'react';
// import HomePageIntro from './HomePageIntro';
// import HomePageText from './HomePageText';
// import HomePageMeetingTimes from './HomePageMeetingTimes';
import './HomePage2.css'
import BFCComponent from '../bfc/BFCComponent'
//import ModalMission from './ModalMission';
import NewsletterSection from '../about/NewsletterSection';
import DonorBox from '../giving/DonorBox';

class HomePage extends BFCComponent {
    render() {
        return (
            <div className="mt-4">
                <div className="container jumbotron jumb0 jumbotron-fluid jumbotron-image text-white shadow">
                    <div className="container text-center">
                        <br />
                        <br />
                        <h1 className="title1">Barvas Free Church</h1>
                        <p className="title2"> "Loving Jesus & Loving People"</p>
                        <br />
                        <br />
                    </div>
                </div>


                <div class="card card0 mb-3 container shadow">
                    <div class="row no-gutters">
                        <div class="col-md-4 my-4 ">
                            <img src="/img/church_interior.png" class="card-img cardImg" alt="Worship" />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body text-center">
                                <h5 class="card-title h2">Join Our Services</h5>
                                <p class="card-text">Morning Service | Every Sunday | 11AM</p>
                                <p class="card-text">Sunday School | Every Sunday | 11AM</p>
                                <p class="card-text">Evening Service | Every Sunday | 6PM </p>
                                <p class="card-text">Bible Study | Every Wedenesday | 7.30PM</p>
                                <p class="card-text">
                                    <a href="/live">
                                        <button class="btn btn-primary" type="button">Watch Live</button>
                                    </a>
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container jumbotron jumb2 jumbotron-fluid jumbotron-image text-white shadow" >
                    <div className="container text-center">
                        <br />
                        <br />
                        <a href="/about"><p class="card-title h1 text-white">Our Mission</p></a>
                        <a href="/about"><p class="card-text text-white" >
                            "We are a church for all ages with a vision to Proclaim the Gospel of Jesus Christ in our community;"
                            </p></a>
                        <br />
                        <br />
                        <br />
                        {/* <ModalMission /> */}
                    </div>
                </div>


                <div class="card card0 mb-3 container shadow">
                    <div class="row no-gutters">
                        <div class="col-md-4 my-4 ">
                            <img src="/img/notice.jpg" class="card-img cardImg" alt="Notice" />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body text-center">
                                <NewsletterSection /> <br />
                            </div>
                        </div>
                    </div>
                </div>
                <DonorBox></DonorBox>

                {/* <div class="card card0 mb-3 container shadow">
                    <div class="row no-gutters">
                        <div class="col-md-4 my-4 ">
                            <a href="/live"><p class="card-title h1 text-white">Watch Live</p></a>
                            <a href="/live"><img src="/img/video_audio.jpg" class="card-img cardImg" alt="Watch Live" /></a>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body text-center">
                                <LivePage /> <br />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}
export default HomePage;
