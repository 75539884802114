import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import Auth from '../auth/Auth.js'
// import AuthNav from '../login/auth-nav'
import './Header.css';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed:true
    }
    this.auth = new Auth();
  }

  navBarToggleClick = (event) => {
    this.setState({
      collapsed:this.state.collapsed ? false : true
    });
  }

  navLinkClick = (event) => {
    this.setState({
      collapsed:true
    });
  }

  login = (event) => {
    this.auth.login();
  }

  render() {

    let collapsedClass = "collapse";

    if(!this.state.collapsed) {
      collapsedClass = "";
    }

    return (
      <nav className="sbc-navbar navbar fixed-top navbar-expand-lg navbar-dark">
       
        <Link to="/" className="navbar-brand" onClick={this.navLinkClick}>
          <img className="sbc-navbar-logo" src="/img/logo-2.jpg" alt="Barvas Free Church"/>
        </Link>
       <button className="navbar-toggler" type="button" aria-label="NavBar Toggler" onClick={this.navBarToggleClick}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={collapsedClass+" navbar-collapse sbc-navbar-collapse"} id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto"></ul>
          <ul className="navbar-nav sbc-navbar-content">
            <li className="nav-item">
              <NavLink to="/" className="nav-link" activeClassName="inactive" onClick={this.navLinkClick}>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sermons" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Sermons</NavLink>
            </li>
           <li className="nav-item">
                <NavLink to="/live" to={{
   pathname:'/live',
   state: {index:'2', value:'2'}  
 }} className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Live</NavLink>
            </li>
             <li className="nav-item">
              <NavLink to="/events" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Events</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>About</NavLink>
            </li>
           
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Contact</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/giving" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Donate</NavLink>
            </li>
            {/*<li className="nav-item">
              <NavLink to="/visit" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Visit</NavLink>
            </li>
             <li className="nav-item">
              <NavLink to="/word-for-the-week" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Word for the Week</NavLink>
            </li>
          <li className="nav-item">
              <NavLink to="/admin" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Admin</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/profile" className="nav-link" activeClassName="active" onClick={this.navLinkClick}>Profile</NavLink>
            </li> */}
          </ul>
        {/* <AuthNav /> */}
        </div>
        {/* <div class="search">
          <div class="gcse-search"></div>
      </div>  */}
      </nav>
    )
  }
};

export default Header;
