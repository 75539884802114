import React from 'react';

const HistorySection = (props) => {

  return (
    <div>
      <h2>HISTORY</h2>
      <h4><strong>Early History</strong></h4>

      <p>Barvas Free Church came into existence following the Disruption in 1843 when many ministers and congregations separated from
      the Established Church of Scotland over patronage, and formed the Church of Scotland Free, later named the Free Church of Scotland.
      Patronage was the system whereby estate owners could impose their own choice of ministers on congregations regardless of the wishes of the parishioners,
      thereby reinforcing their own social status and power.  Following the Disruption, Free Church of Scotland congregations were able to call ministers
      of their own choice and establish clearly defined religious preferences based on Biblical principles and The Westminster Confession of Faith.
      An important characteristic in the governance of the Free Church is its freedom from state interference in its affairs.</p>

      <p> At the insistence of the adherents, who petitioned the Presbytery, a Kirk Session was formed and Barvas Free Church became a sanctioned charge in 1845.
      Almost all of the Barvas Established Church congregation left to associate with the newly-formed Free Church of Scotland.
      In these early years Barvas Free Church was served by visiting preachers and Barvas and Back, together, were served by one minister and one catechist.
      One of the catechists, who served in Barvas before and during this time, was Kenneth Ross, from Crobeg, South Lochs,
      an experienced and highly respected lay preacher who, along with Barvas, had the charge of Carloway and Callanish. </p>

      <p> It is interesting to note that the family of Rev William MacRae, the minister of Barvas Established Church at the time of the Disruption,
      left the Established Church to join the Free Church.  According to oral tradition, Rev. MacRae gave his gig to carry his family to the nearest Free Church.
      Yet, he remained in the Established Church. Many years later, his son, Dr Charles M MacRae, a respected Free Churchman, came to be known as the
      “Grand Old Man of Lews” in his position as an eminent, renowned and greatly esteemed physician who, as a student, was a distinguished gold medallist
      in the class of James Young Simpson, pioneer of chloroform. Dr MacRae named his home in Stornoway, Barvas Lodge, in honour of his birthplace. </p>

      <h4><strong>Barvas Free Church - Early Years</strong></h4>

      <p>
        Barvas Free Church was built in 1850 on its present site and a manse on the site of its present one was built about that time.
        In 1872 the church was enlarged to the extent of 500 additional sittings. The first resident minister of Barvas Free Church was the
        Rev.  Allan MacArthur, a native of Mull, who was inducted in 1857.  Barvas was his first and only charge.
        At that time, when poverty was the normal state of the populace, Rev MacArthur was frequently involved in helping achieve temporary relief for many,
        often without consideration for his own welfare.  Rev MacArthur served in Barvas until his death, in Barvas Free Church manse, on 1st July 1887.
        His last text preached was Nahum 1 v 7, “The Lord is good, a stronghold in the day of trouble, and he knoweth them that trust in him.”
        In 1886, the year before his death, twenty-four people were added to the communion roll.  Rev MacArthur is interred in Tobermory.</p>

      <p>
        The Rev. Neil M Morrison was inducted as Barvas Free Church minister in June 1888.  A native of Leurbost,
        Rev. Morrison was a minister in Gravir Free Church (Pairc) for only about 2 years when he accepted the call
        but he left in 1900 to join the United Free Church.</p>

      <h4><strong>Free Church Buildings</strong></h4>
      <p>

        The Free Church of Scotland buildings were taken over by the United Free church but reverted to the Free Church after a House of Lords ruling in 1904.
        During the years of that legal process concerning property, the Barvas Free church congregation built a church, known as “An Eaglais Shinc” (the Zinc Church),
        so named because of its corrugated metal walls, south of the Gleann Mòr River, near the then disused Free Church.  Its foundation walls still remain.
        The original Free Church building was restored and officially opened in 1933.  The late Annie Finlayson, (Anna Choinnich), of 6 Brue,
        who became a member in the congregation in March 1926, recalled that the opening address was taken from Haggai 1 verses 7 and 8.
        The late Allan Duncan MacKinnon, 18 Brue, elder in the congregation until his passing in 2020, had a clear recollection of those events.
</p>

      <p>
        It is known that the Free Church congregation worshipped in the Barvas School (old Barvas School, Loch Street) on numerous occasions such as the period
        preceding construction of the “Zinc Church”.  Some local historians were of the opinion that the precinct of the old Barvas School and (former) schoolhouse
        belonged to the Free Church and was where the Free Church congregation first worshipped before the church was built in 1850.  Certainly, by 1847,
        there were about 500 schools throughout Scotland connected to the Free Church of Scotland.  In 1853 there was a Free Church School in Lower Barvas,
        situated near where the Monard road junction is now.  The local historians were proved to be accurate in their opinions. It was discovered that Barvas School
        was handed over to the newly established Public School Board by the Free Church after the passing of the
        Education Act of 1872.
 </p>

      <p>
        One of the most widely acclaimed Free Church teachers was Malcolm Nicolson, from Skye, who was schoolmaster in Barvas Free Church School in the early 1850’s.
        He composed several psalm tunes, one of which was named “Barvas”.  Known as one of the “Men of Lewis” he also acted as lay preacher,
        assisting Rev MacArthur as well as other ministers throughout the island.  One of his most noted pupils was Dr Charles M MacRae.
</p>

      <p>
        Some accounts from oral tradition assert that open air worship services were conducted somewhere in the vicinity of the Gleann Mòr river.
</p>
    </div>
  )
}

export default HistorySection;
