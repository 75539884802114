import React from 'react';
import './SermonsPage.css'
import Page from '../page/Page';
import PageBanner from '../page/PageBanner';
import BFCComponent from '../bfc/BFCComponent'

const style = {
  minHeight: "700px",
  minWidth: "100%",
  maxWidth: "100%",
  border: "1px solid #ddd",
  boxSizing: "border-box"
}

class SermonsPage extends BFCComponent {

  render() {
    return (
      <Page>
        <div>
         <hr />
        </div>
        <div>
          <iframe
            src="https://yetanothersermon.host/_/bfc/embed/"
            scrolling="yes"
            style={style}
            frameborder="0"
            width="1"
            height="700"
            title="Yet Another Sermon Host"
          ></iframe>
        </div>
      </Page>
    )
  }
}

export default SermonsPage;
