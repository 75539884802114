import React from 'react';
import Page from '../page/Page';
import './ContactPage.css'
import Map from '../map/Map';
import { Container, Row, Col } from "react-bootstrap";

export default function ContactPage() {

  return (
    <Page>
            <br/>
            <br/>
<h3 class="card-header">Contact</h3>
      <br/>
        <div class="col-md-12">
          <div class="card-body text-center">
          <div class="table-responsive">
          <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Role</th>
                  <th scope="col">Telelphone</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>Rev. Murdo M. Campbell</td>
                  <td>Minister</td>
                  <td>01851 840284/ 01851 840440</td>
                  <td>murdocampbell@live.co.uk</td>
                </tr>

                <tr>
                  <td>Mr Kenny John Macleod </td>
                  <td>Deacons Court Clerk</td>
                  <td>01851 840331</td>
                  <td>kj@barvasfreechurch.org</td>
                </tr>

                <tr>
                  <td>Mr Steven Graham</td>
                  <td>Session Clerk</td>
                  <td>01851 840747</td>
                  <td>stevengraham123@hotmail.com</td>
                </tr>

                <tr>
                  <td>Mr Ivan Brown</td>
                  <td>Treasurer</td>
                  <td>01851 850708</td>
                  <td>ivantheterrible@tinyonline.co.uk</td>
                </tr>

                <tr>
                  <td>Mr David Macmillan</td>
                  <td>Church Officer</td>
                  <td>01851 710510</td>
                  <td>dmac17@btinternet.com</td>
                </tr>

              </tbody>
              </table>
            </div> 
          </div>
        </div>
      <h3 class="card-header">Visit</h3>
      <br/>
        <div class="col-md-12">
          <div class="card-body text-center">
          {/* <table className="table table-responsive table-scrollable">
        <Map
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%`, width: '100%' }} />}></Map>
        </table> */}
        <p> Address: Barvas Free Church, Barvas, Isle of Lewis, <strong>HS2 0QN</strong> </p>

        <p>Our Services on the Lord’s Day are held at 11am &amp; 6pm. We meet during the week at 7.30pm in the Church Hall.
          If you have moved into the area, are on holiday or just passing through, please get in touch. We would love to meet you!
          <br />
          <hr></hr>
          <h6>Accessibility</h6>
          <p>
            Our building has wheelchair access at the rear of the building and accessible toilets on the ground floor.
            <br />Should you have any accessibility requirements, please speak to our welcome team who will be happy to assist you. </p>
        </p>
      </div>
      </div>

    </Page>
  )
}