import React from 'react';
import BibleVerse from '../utils/BibleVerse'

const WelcomeSection = (props) => {
  const style = {
    width: "100%",
    maxWidth: "500px",
    minWidth: "250px",
    maxHeight: "none!important",
  };

  return (
    <div>
      <h2>Welcome to Barvas Free Church</h2>
       <p>
       We are a church for all ages with a vision to <strong>Proclaim</strong> the Gospel of Jesus Christ in our community; 
       to <strong>Pray</strong> for the people in our community; and to <strong>Pastor</strong> the people of our community. Whether you're a Chistian or just investigating the Christian faith, we hope that you will be blessed through the preaching of God's Word.
       </p>

<p>We are a rural congregation located on the west coast of the Isle of Lewis in the Outer Hebrides of Scotland. 
  Although we are from all different ages and backgrounds, we gather together each Lord’s Day to worship God and give thanks for the hope we have through our risen Saviour, Jesus Christ. During the week, we seek to serve the people in our community by practically and personally by getting alongside them and inviting them to come and follow Jesus Christ. 
</p>
<p>If you have moved into the area, are on holiday or just passing through, please get in touch. We would love to meet you! 
</p>
    </div>
  )
}

export default WelcomeSection;
