import React from 'react';
import Page from '../page/Page';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import WhatsOnSection from '../about/WhatsOnSection';
import NewsletterSection from '../about/NewsletterSection';
import CalendarSection from '../calendar/calendar';

const images = [
  { url: "img/slides/testimony.png" },
  { url: "img/testimonyevening.jpg" },
  { url: "img/pulpit.jpg" },
  { url: "img/pilgrimsprogress.jpg" },
];

const sliderOptions = {
  useGPURender: true,
  showNavs: true,
  showBullets: true,
  navStyle: 1,
  duration: 0.5,
  bgColor: '#000'
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

export default function EventsPage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <div>
         <hr />
        </div>
    {/*   <PageBanner title="Events" imageUrl="/img/slides/testimony.png"
       bannerMobilePosition="center"
        titleColour="white" />
<SimpleImageSlider
        align='center'
        width={896}
        height={504}
        images={images}
        showBullets={sliderOptions.showBullets}
        showNavs={sliderOptions.showNavs}
        useGPURender={sliderOptions.useGPURender}
        navStyle={sliderOptions.navStyle}
        slideDuration={sliderOptions.duration}
      /> */}

      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
      >
        <Tab label="Calendar" />
        <Tab label="Service Times" />
        <Tab label="Notices" />
      </Tabs>

      <TabPanel value={value} index={0}>
      <CalendarSection /> <br />
      </TabPanel>

      <TabPanel value={value} index={1}>
      <WhatsOnSection /> <br />
      </TabPanel>

      <TabPanel value={value} index={2}>
      <div id="newsletters"></div>
      <NewsletterSection /> <br />
      </TabPanel>
    </Page>
  )
}
